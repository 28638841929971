<footer>
  <div class="container">
    <div>
      <a class="logo-font" href="//www.inthraction.com" target="_blank">intHRaction</a>
      <span class="attribution">&copy; {{ today | date: 'yyyy' }}.</span>
    </div>
    <div class="footer-center">
      <div class="app-store">
        <a href="https://apps.apple.com/us/app/inthraction/id6736571285?platform=iphone" target="_blank">
          <img ngSrc="/assets/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg" alt="App Store" height="40" width="120">
        </a>
      </div>
      <div class="tos"><a href="/tos">Terms of Service</a></div>
    </div>
    <div class="version-holder">
      <div *ngIf="authenticator.route == 'authenticated'" class="version">{{version}}</div>
    </div>
    <inthraction-headway *ngIf="authenticator.route == 'authenticated'"></inthraction-headway>
    <inthraction-zendesk *ngIf="authenticator.route == 'authenticated'"></inthraction-zendesk>
  </div>
</footer>
