<inthraction-page-header [pageTitle]="'System Configuration' + (isShowOrganizationName? ' for '+ organizationName : '')"></inthraction-page-header>
<div class="system-configuration-wrapper" *ngIf="initDone">
  <div>
    <mat-card appearance="outlined" class="half-card">
      <mat-card-header>
        <mat-card-title>Survey Configurations</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Disable int<span class="inthraction-highlight">HR</span>action Surveys</mat-label>
          <mat-slide-toggle (change)="eventSurveyDisabled=$event.checked" [checked]="eventSurveyDisabled"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Enable New Hire 14 Day Experience Surveys</mat-label>
          <mat-slide-toggle (change)="newHire14DayEnabled=$event.checked" [checked]="newHire14DayEnabled"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Enable New Hire 90 Day Experience Surveys</mat-label>
          <mat-slide-toggle (change)="newHire90DayEnabled=$event.checked" [checked]="newHire90DayEnabled"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Enable New Manager 30 Day Experience Surveys</mat-label>
          <mat-slide-toggle (change)="newManager30DayEnabled=$event.checked" [checked]="newManager30DayEnabled"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Enable New Manager 90 Day Experience Surveys</mat-label>
          <mat-slide-toggle (change)="newManager90DayEnabled=$event.checked" [checked]="newManager90DayEnabled"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Enable New Position 60 Day Experience Surveys</mat-label>
          <mat-slide-toggle (change)="newPosition60DayEnabled=$event.checked" [checked]="newPosition60DayEnabled"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Open int<span class="inthraction-highlight">HR</span>action Surveys will expire after {{formatDaysDisplay(inthractionSurveyCutoff)}}</mat-label>
          <mat-slider min="0" max="7" step="1" thumbLabel [displayWith]="formatDaysLabel"><input matSliderThumb [(value)]="inthractionSurveyCutoff" /></mat-slider>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Open Objective Surveys will expire after {{formatDaysDisplay(objectiveSurveyCutoff)}}</mat-label>
          <mat-slider min="0" max="15" step="1" thumbLabel [displayWith]="formatDaysLabel"><input matSliderThumb [(value)]="objectiveSurveyCutoff" /></mat-slider>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Open Project Surveys will expire after {{formatDaysDisplay(projectSurveyCutoff)}}</mat-label>
          <mat-slider min="0" max="15" step="1" thumbLabel [displayWith]="formatDaysLabel"><input matSliderThumb [(value)]="projectSurveyCutoff" /></mat-slider>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Open Project Milestone Surveys will expire after {{formatDaysDisplay(milestoneSurveyCutoff)}}</mat-label>
          <mat-slider min="0" max="15" step="1" thumbLabel [displayWith]="formatDaysLabel"><input matSliderThumb [(value)]="milestoneSurveyCutoff" /></mat-slider>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Send an Objective Survey after {{objecvtiveIntHRactionCount}} int<span class="inthraction-highlight">HR</span>actions</mat-label>
          <mat-slider min="2" max="30" step="1" thumbLabel><input matSliderThumb [(value)]="objecvtiveIntHRactionCount" /></mat-slider>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button #surveySubmitBtn mat-raised-button (click)="saveSurveyConfigurations(surveySubmitBtn)" type="submit">Save</button>
        <button mat-button (click)="resetSurveyConfigurations()">Cancel</button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div>
    <mat-card appearance="outlined" class="half-card">
      <mat-card-header>
        <mat-card-title>Manager Configurations</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Disable Managers ability to assign Objectives</mat-label>
          <mat-slide-toggle (change)="managerObjectivesDisabled=$event.checked" [checked]="managerObjectivesDisabled"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button #managerSubmitBtn mat-raised-button (click)="saveManagerConfigurations(managerSubmitBtn)" type="submit">Save</button>
        <button mat-button (click)="resetManagerConfigurations()">Cancel</button>
      </mat-card-actions>
    </mat-card>
    <mat-card appearance="outlined" class="half-card">
      <mat-card-header>
        <mat-card-title>Readiness Configurations</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field class="full-width">
          <mat-label>Readiness Evaluation Cadence</mat-label>
          <mat-select [(value)]="readinessEvaluationCadence">
            <mat-option *ngFor="let cadence of READINESS_CADENCE_TYPES" [value]="cadence">{{READINESS_CADENCE_LABELS[cadence]}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button #readinessSubmitBtn mat-raised-button (click)="saveReadinessConfigurations(readinessSubmitBtn)" type="submit">Save</button>
        <button mat-button (click)="resetReadinessConfigurations()">Cancel</button>
      </mat-card-actions>
    </mat-card>
    <mat-card appearance="outlined" class="half-card">
      <mat-card-header>
        <mat-card-title>Employee Configurations</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>Allow emails outside of Organization Domain</mat-label>
          <mat-slide-toggle (change)="openEmailDomainEnabled=$event.checked" [checked]="openEmailDomainEnabled"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button #employeeSubmitBtn mat-raised-button (click)="saveEmployeeConfigurations(employeeSubmitBtn)" type="submit">Save</button>
        <button mat-button (click)="resetEmployeeConfigurations()">Cancel</button>
      </mat-card-actions>
    </mat-card>
    <mat-card appearance="outlined" class="half-card">
      <mat-card-header>
        <mat-card-title>Integrations</mat-card-title>
      </mat-card-header>
      <mat-card-content *ngIf="site === SITE_CODES.DEALHRSHIP">
        <mat-form-field floatLabel="always" appearance="outline" class="slide-toggle-form-field full-width">
          <mat-label>RockED</mat-label>
          <mat-slide-toggle (change)="rockedIntegrationEnabled=$event.checked" [checked]="rockedIntegrationEnabled"></mat-slide-toggle>
          <input matInput hidden height="0" style="height: 0" />
          <mat-hint>RockED Performance Development for the Automotive Industry <a href="https://www.rocked.us/" target="_blank">https://www.rocked.us/</a></mat-hint>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions>
        <button #integrationsSubmitBtn mat-raised-button (click)="saveIntegrationConfigurations(integrationsSubmitBtn)" type="submit">Save</button>
        <button mat-button (click)="resetIntegrationConfigurations()">Cancel</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<inthraction-loading className="system-configuration-wrapper" [initDone]="initDone"></inthraction-loading>
