import { Component, OnDestroy, OnInit } from "@angular/core";
import { EmployeeService, OrganizationService } from "@inthraction/services";
import { OrganizationConfigurationCodes, READINESS_CADENCE_TYPES, SiteCodes } from "@inthraction/codes";
import { Employee, Organization, OrganizationConfiguration } from "@inthraction/data-models";
import { ToastrService } from "ngx-toastr";
import { MatButton } from "@angular/material/button";
import { READINESS_CADENCE_LABELS } from "@inthraction/labels";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "inthraction-system-configuration",
  templateUrl: "./system-configuration.component.html",
  styleUrls: ["./system-configuration.component.scss"]
})
export class SystemConfigurationComponent implements OnInit, OnDestroy {

  static organizationIDParameterName = "organizationID";

  readonly READINESS_CADENCE_LABELS = READINESS_CADENCE_LABELS;
  readonly READINESS_CADENCE_TYPES = READINESS_CADENCE_TYPES;
  readonly SITE_CODES = SiteCodes;

  inthractionSurveyCutoff: number;
  objectiveSurveyCutoff: number;
  projectSurveyCutoff: number;
  milestoneSurveyCutoff: number;
  objecvtiveIntHRactionCount: number;
  eventSurveyDisabled: boolean;

  newHire14DayEnabled: boolean;
  newHire90DayEnabled: boolean;
  newManager30DayEnabled: boolean;
  newManager90DayEnabled: boolean;
  newPosition60DayEnabled: boolean;

  managerObjectivesDisabled: boolean;
  readinessEvaluationCadence: string;
  openEmailDomainEnabled: boolean;
  rockedIntegrationEnabled: boolean;


  initDone = false;
  private subscriptions: Subscription[] = [];
  private organization: Organization;
  private eventConfiguration: OrganizationConfiguration;
  private objectiveConfiguration: OrganizationConfiguration;
  private projectConfiguration: OrganizationConfiguration;
  private milestoneConfiguration: OrganizationConfiguration;
  private objectiveIntHRactionConfiguration: OrganizationConfiguration;
  private eventSurveyDisabledConfiguration: OrganizationConfiguration;
  private managerObjectivesDisabledConfiguration: OrganizationConfiguration;
  private readinessEvaluationCadenceConfiguration: OrganizationConfiguration;
  private openEmailDomainEnabledConfiguration: OrganizationConfiguration;
  private rockedIntegrationEnabledConfiguration: OrganizationConfiguration;

  private newHire14DayConfiguration: OrganizationConfiguration;
  private newHire90DayConfiguration: OrganizationConfiguration;
  private newManager30DayConfiguration: OrganizationConfiguration;
  private newManager90DayConfiguration: OrganizationConfiguration;
  private newPosition60DayConfiguration: OrganizationConfiguration;
  private currentUser: Employee;



  constructor(
    private route: ActivatedRoute,
    private organizationService: OrganizationService,
    private employeeService: EmployeeService,
    private toastr: ToastrService
  ) {
  }

  get site() {
    return this.organization?.site;
  }

  async ngOnInit() {

    this.subscriptions.push( this.route.paramMap.subscribe(async queryParams => {
      if (queryParams.has(SystemConfigurationComponent.organizationIDParameterName)) {
        this.organization = await this.organizationService.getOrganizationByIDMemoize(queryParams.get(SystemConfigurationComponent.organizationIDParameterName));
      } else {
        this.organization = await this.organizationService.getOrganizationForCurrentUser();
      }
      await this.initializeSurveyConfigurations();
      await this.initializeManagerConfigurations();
      await this.initializeReadinessConfigurations();
      await this.initializeEmployeeConfigurations();
      await this.initializeIntegrationConfigurations();
      this.initDone = true;
    }));

    this.currentUser = await this.employeeService.getCurrentEmployee();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      for (const sub of this.subscriptions) {
        sub.unsubscribe();
      }
    }
  }

  get isShowOrganizationName(): boolean {
    return this.organization && this.currentUser?.orgId !== this.organization?.id;
  }

  get organizationName(): string {
    return this.organization?.orgName;
  }

  private async initializeManagerConfigurations() {
    this.managerObjectivesDisabledConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.MANAGER_OBJECTIVES_DISABLED, this.organization.id);
    if (this.managerObjectivesDisabledConfiguration) {
      this.managerObjectivesDisabled = this.managerObjectivesDisabledConfiguration.configBooleanValue;
    } else {
      this.managerObjectivesDisabled = false;
    }
  }

  async initializeSurveyConfigurations() {
    this.eventSurveyDisabledConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.EVENT_SURVEY_DISABLED, this.organization.id);
    if (this.eventSurveyDisabledConfiguration) {
      this.eventSurveyDisabled = this.eventSurveyDisabledConfiguration.configBooleanValue;
    } else {
      this.eventSurveyDisabled = false;
    }

    this.eventConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.EVENT_MISSED_DAYS, this.organization.id);
    if (this.eventConfiguration) {
      this.inthractionSurveyCutoff = this.eventConfiguration.configIntValue;
    } else {
      this.inthractionSurveyCutoff = 2;
    }

    this.objectiveConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.OBJECTIVE_MISSED_DAYS, this.organization.id);
    if (this.objectiveConfiguration) {
      this.objectiveSurveyCutoff = this.objectiveConfiguration.configIntValue;
    } else {
      this.objectiveSurveyCutoff = 2;
    }

    this.projectConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.PROJECT_MISSED_DAYS, this.organization.id);
    if (this.projectConfiguration) {
      this.projectSurveyCutoff = this.projectConfiguration.configIntValue;
    } else {
      this.projectSurveyCutoff = 2;
    }

    this.milestoneConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.MILESTONE_MISSED_DAYS, this.organization.id);
    if (this.milestoneConfiguration) {
      this.milestoneSurveyCutoff = this.milestoneConfiguration.configIntValue;
    } else {
      this.milestoneSurveyCutoff = 2;
    }

    this.objectiveIntHRactionConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.OBJECTIVE_INTHRACTION_COUNT, this.organization.id);
    if (this.objectiveIntHRactionConfiguration) {
      this.objecvtiveIntHRactionCount = this.objectiveIntHRactionConfiguration.configIntValue;
    } else {
      this.objecvtiveIntHRactionCount = 10;
    }

    this.newHire14DayConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.NEW_HIRE_14_DAY, this.organization.id);
    if (this.newHire14DayConfiguration) {
      this.newHire14DayEnabled = this.newHire14DayConfiguration.configBooleanValue;
    } else {
      this.newHire14DayEnabled = false;
    }

    this.newHire90DayConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.NEW_HIRE_90_DAY, this.organization.id);
    if (this.newHire90DayConfiguration) {
      this.newHire90DayEnabled = this.newHire90DayConfiguration.configBooleanValue;
    } else {
      this.newHire90DayEnabled = false;
    }

    this.newManager30DayConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.NEW_MANAGER_30_DAY, this.organization.id);
    if (this.newManager30DayConfiguration) {
      this.newManager30DayEnabled = this.newManager30DayConfiguration.configBooleanValue;
    } else {
      this.newManager30DayEnabled = false;
    }

    this.newManager90DayConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.NEW_MANAGER_90_DAY, this.organization.id);
    if (this.newManager90DayConfiguration) {
      this.newManager90DayEnabled = this.newManager90DayConfiguration.configBooleanValue;
    } else {
      this.newManager90DayEnabled = false;
    }

    this.newPosition60DayConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.NEW_POSITION_60_DAY, this.organization.id);
    if (this.newPosition60DayConfiguration) {
      this.newPosition60DayEnabled = this.newPosition60DayConfiguration.configBooleanValue;
    } else {
      this.newPosition60DayEnabled = false;
    }

  }

  async initializeReadinessConfigurations() {
    this.readinessEvaluationCadenceConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.READINESS_EVALUATION_CADENCE, this.organization.id);
    if (this.readinessEvaluationCadenceConfiguration) {
      this.readinessEvaluationCadence = this.readinessEvaluationCadenceConfiguration.configStringValue;
    } else {
      this.readinessEvaluationCadence = "DISABLED";
    }
  }

  async initializeEmployeeConfigurations() {
    this.openEmailDomainEnabledConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.OPEN_EMAIL_DOMAIN, this.organization.id);
    if (this.openEmailDomainEnabledConfiguration) {
      this.openEmailDomainEnabled = this.openEmailDomainEnabledConfiguration.configBooleanValue;
    } else {
      this.openEmailDomainEnabled = false;
    }
  }

  async initializeIntegrationConfigurations() {
    this.rockedIntegrationEnabledConfiguration = await this.organizationService.getOrganizationConfiguration(OrganizationConfigurationCodes.ROCKED_INTEGRATION, this.organization.id);

    if (this.rockedIntegrationEnabledConfiguration) {
      this.rockedIntegrationEnabled = this.rockedIntegrationEnabledConfiguration.configBooleanValue;
    } else {
      this.rockedIntegrationEnabled = false;
    }
  }

  formatDaysLabel(value: number) {
    if (value === 0) {
      return "\u{0221E}";
    }
    return value;
  }

  formatDaysDisplay(value: number): string {
    if (value === 0) {
      return "\u{0221E} Days";
    } else if (value === 1) {
      return `${value} Day`;
    }
    return `${value} Days`;
  }

  async saveSurveyConfigurations(submitBtn: MatButton | HTMLButtonElement) {
    submitBtn.disabled = true;
    try {
      if (this.eventSurveyDisabledConfiguration) {
        if (this.eventSurveyDisabledConfiguration.configBooleanValue !== this.eventSurveyDisabled) {
          this.eventSurveyDisabledConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.eventSurveyDisabledConfiguration.id,
            configBooleanValue: this.eventSurveyDisabled
          });
        }
      } else {
        this.eventSurveyDisabledConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.EVENT_SURVEY_DISABLED,
          configBooleanValue: this.eventSurveyDisabled
        } as OrganizationConfiguration);
      }

      if (this.newHire14DayConfiguration) {
        if (this.newHire14DayConfiguration.configBooleanValue !== this.newHire14DayEnabled) {
          this.newHire14DayConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.newHire14DayConfiguration.id,
            configBooleanValue: this.newHire14DayEnabled
          });
        }
      } else {
        this.newHire14DayConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.NEW_HIRE_14_DAY,
          configBooleanValue: this.newHire14DayEnabled
        } as OrganizationConfiguration);
      }

      if (this.newHire90DayConfiguration) {
        if (this.newHire90DayConfiguration.configBooleanValue !== this.newHire90DayEnabled) {
          this.newHire90DayConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.newHire90DayConfiguration.id,
            configBooleanValue: this.newHire90DayEnabled
          });
        }
      } else {
        this.newHire90DayConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.NEW_HIRE_90_DAY,
          configBooleanValue: this.newHire90DayEnabled
        } as OrganizationConfiguration);
      }

      if (this.newManager30DayConfiguration) {
        if (this.newManager30DayConfiguration.configBooleanValue !== this.newManager30DayEnabled) {
          this.newManager30DayConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.newManager30DayConfiguration.id,
            configBooleanValue: this.newManager30DayEnabled
          });
        }
      } else {
        this.newManager30DayConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.NEW_MANAGER_30_DAY,
          configBooleanValue: this.newManager30DayEnabled
        } as OrganizationConfiguration);
      }

      if (this.newManager90DayConfiguration) {
        if (this.newManager90DayConfiguration.configBooleanValue !== this.newManager90DayEnabled) {
          this.newManager90DayConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.newManager90DayConfiguration.id,
            configBooleanValue: this.newManager90DayEnabled
          });
        }
      } else {
        this.newManager90DayConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.NEW_MANAGER_90_DAY,
          configBooleanValue: this.newManager90DayEnabled
        } as OrganizationConfiguration);
      }

      if (this.newPosition60DayConfiguration) {
        if (this.newPosition60DayConfiguration.configBooleanValue !== this.newPosition60DayEnabled) {
          this.newPosition60DayConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.newPosition60DayConfiguration.id,
            configBooleanValue: this.newPosition60DayEnabled
          });
        }
      } else {
        this.newPosition60DayConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.NEW_POSITION_60_DAY,
          configBooleanValue: this.newPosition60DayEnabled
        } as OrganizationConfiguration);
      }

      if (this.eventConfiguration) {
        if (this.eventConfiguration.configIntValue !== this.inthractionSurveyCutoff) {
          this.eventConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.eventConfiguration.id,
            configIntValue: this.inthractionSurveyCutoff
          });
        }
      } else {
        this.eventConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.EVENT_MISSED_DAYS,
          configIntValue: this.inthractionSurveyCutoff
        } as OrganizationConfiguration);
      }

      if (this.objectiveConfiguration) {
        if (this.objectiveConfiguration.configIntValue !== this.objectiveSurveyCutoff) {
          this.objectiveConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.objectiveConfiguration.id,
            configIntValue: this.objectiveSurveyCutoff
          });
        }
      } else {
        this.objectiveConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.OBJECTIVE_MISSED_DAYS,
          configIntValue: this.objectiveSurveyCutoff
        } as OrganizationConfiguration);
      }

      if (this.projectConfiguration) {
        if (this.projectConfiguration.configIntValue !== this.projectSurveyCutoff) {
          this.projectConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.projectConfiguration.id,
            configIntValue: this.projectSurveyCutoff
          });
        }
      } else {
        this.projectConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.PROJECT_MISSED_DAYS,
          configIntValue: this.projectSurveyCutoff
        } as OrganizationConfiguration);
      }

      if (this.milestoneConfiguration) {
        if (this.milestoneConfiguration.configIntValue !== this.milestoneSurveyCutoff) {
          this.milestoneConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.milestoneConfiguration.id,
            configIntValue: this.milestoneSurveyCutoff
          });
        }
      } else {
        this.milestoneConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.MILESTONE_MISSED_DAYS,
          configIntValue: this.milestoneSurveyCutoff
        } as OrganizationConfiguration);
      }

      if (this.objectiveIntHRactionConfiguration) {
        if (this.objectiveIntHRactionConfiguration.configIntValue !== this.objecvtiveIntHRactionCount) {
          this.objectiveIntHRactionConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.objectiveIntHRactionConfiguration.id,
            configIntValue: this.objecvtiveIntHRactionCount
          });
        }
      } else {
        this.objectiveIntHRactionConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.OBJECTIVE_INTHRACTION_COUNT,
          configIntValue: this.objecvtiveIntHRactionCount
        } as OrganizationConfiguration);
      }
      submitBtn.disabled = false;
      this.toastr.success("Configurations Updated");
    } catch (error) {
      console.error(error);
      this.toastr.error("Unable to update Configurations");
      submitBtn.disabled = false;
    }
  }

  async resetSurveyConfigurations() {
    await this.initializeSurveyConfigurations();
    this.toastr.info("Update Canceled");
  }

  async saveManagerConfigurations(submitBtn: MatButton | HTMLButtonElement) {
    submitBtn.disabled = true;

    try {
      if (this.managerObjectivesDisabledConfiguration) {
        if (this.managerObjectivesDisabledConfiguration.configBooleanValue !== this.managerObjectivesDisabled) {
          this.managerObjectivesDisabledConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.managerObjectivesDisabledConfiguration.id,
            configBooleanValue: this.managerObjectivesDisabled
          });
        }
      } else {
        this.managerObjectivesDisabledConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.MANAGER_OBJECTIVES_DISABLED,
          configBooleanValue: this.managerObjectivesDisabled
        } as OrganizationConfiguration);
      }

      submitBtn.disabled = false;
      this.toastr.success("Configurations Updated");
    } catch (error) {
      console.error(error);
      this.toastr.error("Unable to update Configurations");
      submitBtn.disabled = false;
    }
  }

  async resetManagerConfigurations() {
    await this.initializeManagerConfigurations();
    this.toastr.info("Update Canceled");
  }

  async saveEmployeeConfigurations(submitBtn: MatButton | HTMLButtonElement) {
    submitBtn.disabled = true;

    try {
      if (this.openEmailDomainEnabledConfiguration) {
        if (this.openEmailDomainEnabledConfiguration.configBooleanValue !== this.openEmailDomainEnabled) {
          this.openEmailDomainEnabledConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.openEmailDomainEnabledConfiguration.id,
            configBooleanValue: this.openEmailDomainEnabled
          });
        }
      } else {
        this.openEmailDomainEnabledConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.OPEN_EMAIL_DOMAIN,
          configBooleanValue: this.openEmailDomainEnabled
        } as OrganizationConfiguration);
      }

      submitBtn.disabled = false;
      this.toastr.success("Configurations Updated");
    } catch (error) {
      console.error(error);
      this.toastr.error("Unable to update Configurations");
      submitBtn.disabled = false;
    }
  }

  async resetEmployeeConfigurations() {
    await this.initializeEmployeeConfigurations();
    this.toastr.info("Update Canceled");
  }


  async saveReadinessConfigurations(submitBtn: MatButton | HTMLButtonElement) {
    submitBtn.disabled = true;

    try {
      if (this.readinessEvaluationCadenceConfiguration) {
        if (this.readinessEvaluationCadenceConfiguration.configStringValue !== this.readinessEvaluationCadence) {
          this.readinessEvaluationCadenceConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.readinessEvaluationCadenceConfiguration.id,
            configStringValue: this.readinessEvaluationCadence
          });
        }
      } else {
        this.readinessEvaluationCadenceConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.READINESS_EVALUATION_CADENCE,
          configStringValue: this.readinessEvaluationCadence
        } as OrganizationConfiguration);
      }

      submitBtn.disabled = false;
      this.toastr.success("Configurations Updated");
    } catch (error ) {
      console.error(error);
      this.toastr.error("Unable to update Configurations");
      submitBtn.disabled = false;
    }
  }

  async resetReadinessConfigurations() {
    await this.initializeReadinessConfigurations();
    this.toastr.info("Update Canceled");
  }

  async saveIntegrationConfigurations(submitBtn: MatButton) {
    submitBtn.disabled = true;

    try {
      if (this.rockedIntegrationEnabledConfiguration) {
        if (this.rockedIntegrationEnabledConfiguration.configBooleanValue !== this.rockedIntegrationEnabled) {
          this.rockedIntegrationEnabledConfiguration = await this.organizationService.updateOrganizationConfiguration({
            id: this.rockedIntegrationEnabledConfiguration.id,
            configBooleanValue: this.rockedIntegrationEnabled
          });
        }
      } else {
        this.rockedIntegrationEnabledConfiguration = await this.organizationService.createOrganizationConfiguration({
          organizationID: this.organization.id,
          configCode: OrganizationConfigurationCodes.ROCKED_INTEGRATION,
          configBooleanValue: this.rockedIntegrationEnabled
        } as OrganizationConfiguration);
      }

      submitBtn.disabled = false;
      this.toastr.success("Configurations Updated");
    } catch (error) {
      console.error(error);
      this.toastr.error("Unable to update Configurations");
      submitBtn.disabled = false;
    }
  }

  async resetIntegrationConfigurations() {
    await this.initializeIntegrationConfigurations();
    this.toastr.info("Update Canceled");
  }
}
